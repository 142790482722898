/**
 * Created by xiongxiaomeng.
 * 方案相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('scheme/info', params)},
    list: params => {  return API.GET('scheme/list', params)},
    get: params => {  return API.GET('scheme/get', params)},
    delete: params => { return API.DELETE('scheme/delete', params) },
    download: params => { return API.POST('scheme/download', params) },
    review: params => {  return API.POST('scheme/review', params)},
}
