/**
 * 新建订单， 提交方案， 驳回方案,待进行订单，结稿订单
 * 
 */

export default {
    NEW: 0,
    STARTVERIFY: 1,
    INCOMPLETE: 2,
    COMMITVERIFYPLAN: 3,
    REFUSEVERIFYPLAN: 4,
    STARTCOLLECT: 5,
    ENDCOLLECT: 6,
    COMPLETE: 7,
    CANCEL:100,
    getLabelByValue: (value) => {
        switch (value) {
            case 0:
                return "创建订单";
            case 1:
                return "开始验证";
            case 2:
                return "结束验证";
            case 3:
                return "提交方案";
            case 4:
                return "驳回方案";
            case 5:
                return "开始采集";
            case 6:
                return "结束采集";
            case 7:
                return "办结订单";
            default:
                return "未定";

        }
    },


}