/**
 * Created by xiongxiaomeng.
 * 订单跟踪相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('ordertrace/info', params)},
    list: params => {  return API.GET('ordertrace/list', params)},
    get: params => {  return API.GET('ordertrace/get', params)},
    delete: params => {  return API.DELETE('ordertrace/delete', params)}
}
