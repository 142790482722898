<template>
  <div>
    <div class="row fl">订单编号：{{ orderInfo?.orderno }}</div>
    <div class="row fl center">{{ customerInfo?.name }}</div>
    <div class="clear"></div>
    <div class="dispatch-box">
      <el-row :gutter="20">
        <el-col :span="6"><span class="fr">所属要部门：</span></el-col>
        <el-col :span="18">{{ deptInfo?.name }}</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6"><span class="fr">负责人：</span></el-col>
        <el-col :span="18">{{ workerInfo?.name }}</el-col>
      </el-row>
    </div>
    <div class="tabs-box">
      <el-tabs
        type="border-card"
        v-model="selectedTab"
        @tab-click="handler_tabclick"
      >
        <el-tab-pane label="订单详情" name="first">
          <div class="row center">项目列表</div>
          <div class="block-box">
            <el-table
              :data="detailList"
              v-loading="showLoading"
              element-loading-text="拼命加载中"
              border
              width="width:100%; height:50%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="project.reportno"
                label="项目编号"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="project.name"
                label="项目名称"
              ></el-table-column>

              <el-table-column
                prop="project.status"
                label="状态"
                width="80"
                :formatter="formatterProjectStatus"
              ></el-table-column>
              <el-table-column
                prop="project.addtime"
                label="建立时间"
                width="180"
              ></el-table-column>
              <el-table-column prop="scope" label="操作" width="100">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="el-icon-remove"
                    v-if="orderInfo.status == 0 && auth['order:deleteDetail']"
                    @click="handler_deletedetail(scope.row)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="clear"></div>
          <div class="block-box">
            <label class="label">方案</label
            ><span
              ><el-button
                v-if="schemeInfo && auth['scheme:download']"
                type="text"
                icon="el-icon-download"
                @click="handler_schemedownload"
              ></el-button
              ><el-button
                v-if="schemeInfo && auth['scheme:get']"
                type="text"
                icon="el-icon-view"
                @click="handler_schemereview"
              ></el-button
            ></span>
          </div>

          <div class="clear"></div>
          <div class="block-box">
            <label class="label">报告</label
            ><span
              ><el-button
                v-if="reportInfo && auth['report:download']"
                type="text"
                icon="el-icon-download"
                @click="handler_reportdownload"
              ></el-button
              ><el-button
                v-if="reportInfo && auth['report:get']"
                type="text"
                icon="el-icon-view"
                @click="handler_reportreview"
              ></el-button
            ></span>
          </div>
          <div class="clear"></div>
          <div class="row center">
            快递地址<el-button
              type="text"
              icon="el-icon-edit-outline"
              class="fr"
              v-if="computeOrderExpressEdit && auth['order:updateAddress']"
              @click="handler_openCustomerInfoDialog"
              >编辑</el-button
            >
          </div>
          <div class="block-box">
            <!-- <div class="row fl">
              <label class="label">客户名称</label
              ><span>{{ customerInfo.name }}</span>
            </div> -->
            <div class="row fl">
              <label class="label">联系人</label
              ><span>{{ customerInfo.contact }}</span>
            </div>
            <div class="row fl">
              <label class="label">联系电话</label
              ><span>{{ customerInfo.phone }}</span>
            </div>
            <div class="row fl">
              <label class="label">省市区</label
              ><span>{{ customerInfo.province }}</span
              ><span>{{ customerInfo.city }}</span>
              <span>{{ customerInfo.area }}</span>
            </div>
            <div class="row fl">
              <label class="label">详细地址</label
              ><span>{{ customerInfo.address }}</span>
            </div>
          </div>
          <div class="clear"></div>
          <div class="buttons">
            <el-button
              type="primary"
              v-if="orderInfo.status == 0"
              @click="handler_orderstartverify()"
              >开始验证</el-button
            >
            <el-button
              type="primary"
              v-if="orderInfo.status == 2"
              @click="handler_orderstartcollect()"
              >开始采集</el-button
            >
            <el-button
              type="primary"
              v-if="orderInfo.status == 4"
              @click="handler_ordercomplete()"
              >办结订单</el-button
            >

            <el-button
              type="primary"
              v-if="computeOrderDispatchDept"
              @click="showOrderDispatchDeptDialog = true"
              >转交部门</el-button
            >

            <el-button
              type="primary"
              v-if="computeOrderDispatchUser"
              @click="showOrderDispatchUserDialog = true"
              >指定负责人</el-button
            >
            <!-- <el-button @click="handler_ordercancel" v-if="computeOrderCancel"
          >订单取消</el-button
        > -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="订单动态" name="second">
          <el-table
            :data="traceList"
            v-loading="showTraceLoading"
            element-loading-text="拼命加载中"
            border
            width="width:100%; height:50%"
          >
            <el-table-column
              type="index"
              label="序号"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="custid"
              label="客户"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="tracetype"
              label="类型"
              width="100"
              :formatter="formatterOrderTraceType"
            ></el-table-column>
            <el-table-column prop="tracedesc" label="详细"></el-table-column>
            <el-table-column
              prop="addempid"
              label="操作人员"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="addtime"
              label="建立时间"
              width="180"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!--record log dialog-->
    <el-dialog
      title="编辑快递收件人信息"
      :close-on-click-modal="false"
      width="1000px"
      v-model="showCustomerInfoDialog"
      @open="handler_openCustomerInfoDialog()"
      @close="handler_closeCustomerInfoDialog()"
    >
      <el-form
        :model="selectCustomerInfo"
        ref="selectCustomerInfo"
        :rules="editSelectCustomerInfoRules"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="18">
            <el-form-item label="联系人" label-width="150px" prop="contact">
              <el-input v-model="selectCustomerInfo.contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系电话" label-width="150px" prop="phone">
              <el-input v-model="selectCustomerInfo.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="省市区" label-width="140px" prop="province">
              <el-col :span="6">
                <el-form-item label="" label-width="0" prop="province">
                  <el-select
                    @change="handler_changeProvince"
                    v-model="selectCustomerInfo.province"
                    placeholder="请选择省级地址"
                  >
                    <el-option
                      v-for="item in selectProvinceList"
                      :key="item.id"
                      :label="item.areaName"
                      :value="item.areaName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="" label-width="0" prop="city">
                  <el-select
                    @change="handler_changeCity"
                    v-model="selectCustomerInfo.city"
                    placeholder="请选择市级地址"
                  >
                    <el-option
                      v-for="item in selectCityList"
                      :key="item.id"
                      :label="item.areaName"
                      :value="item.areaName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="" label-width="0" prop="area">
                  <el-select
                    v-model="selectCustomerInfo.area"
                    placeholder="请选择县区"
                  >
                    <el-option
                      v-for="item in selectAreaList"
                      :key="item.id"
                      :label="item.areaName"
                      :value="item.areaName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="18">
            <el-form-item label="详细地址" label-width="150px" prop="address">
              <el-input
                v-model="selectCustomerInfo.address"
                placeholder="请输入详细地址"
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="edit_save()">确定</el-button>
          <el-button @click="handler_closeCustomerInfoDialog()">关闭</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!--scheme view dialog-->
    <el-dialog
      title="阅览方案"
      :close-on-click-modal="false"
      width="1000px"
      class="dialog"
      v-model="showSchemeInfoDialog"
      @close="showSchemeInfoDialog = false"
    >
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">客户</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.custid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">方案名称</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.name }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">方案内容</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.content }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核意见</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examopinion }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examuid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">下载次数</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.downloadcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">预览次数</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.reviewcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.addtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.addempid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.updatetime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.updateempid }}
        </el-col>
      </el-row>
      <div class="row"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSchemeInfoDialog = false">关闭</el-button>
      </div>
    </el-dialog>

    <!--report view dialog-->
    <el-dialog
      title="阅览报告"
      :close-on-click-modal="false"
      width="1000px"
      class="dialog"
      v-model="showReportInfoDialog"
      @close="showReportInfoDialog = false"
    >
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">客户</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.custid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">报告名称</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.name }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">报告内容</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.content }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核意见</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.examopinion }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人id</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.examuid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人时间</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.examtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">下载次数</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.downloadcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">预览次数</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.reviewcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建时间</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.addtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建人id</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.addempid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新时间</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.updatetime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新人id</span>
        </el-col>
        <el-col :span="20">
          {{ reportInfo.updateempid }}
        </el-col>
      </el-row>
      <div class="row"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showReportInfoDialog = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--order dispatch dept-->
    <el-dialog
      title="转交部门"
      :close-on-click-modal="false"
      width="600px"
      v-model="showOrderDispatchDeptDialog"
    >
      <el-row>
        <el-col :span="24">
          <el-tree
            class="dialog-box"
            lazy
            highlight-current
            default-expand-all
            :expand-on-click-node="false"
            ref="treeDept"
            node-key="id"
            :load="loadTreeNodeDept"
            @node-click="handler_setCurrentTreeNode"
            :props="defaultProps"
          >
          </el-tree>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showOrderDispatchDeptDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="handler_saveOrderDispatchDetp()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--order dispatch user-->
    <el-dialog
      title="指定订单负责人"
      :close-on-click-modal="false"
      width="600px"
      v-model="showOrderDispatchUserDialog"
    >
      <el-row>
        <el-col :span="24">
          <el-tree
            lazy
            class="dialog-box"
            highlight-current
            default-expand-all
            :expand-on-click-node="false"
            ref="treeUser"
            node-key="id"
            :load="loadTreeNodeUser"
            @node-click="handler_setCurrentTreeNode"
            :props="defaultProps"
          >
          </el-tree>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showOrderDispatchUserDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="handler_saveOrderDispatchUser()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ORDER from "../../api/biz/api_order";
import ORDERTRACE from "../../api/biz/api_ordertrace";
import CUST from "../../api/biz/api_customer";
import AREA from "../../api/base/api_area";
import OrderStatus from "./order_status";
import ProjectStatus, { getLabelByValue } from "../project/project_status";
import OrderTraceType from "./ordertrace_type";
import SCHEME from "../../api/biz/api_scheme";
import REPORT from "../../api/biz/api_report";
import DEPT from "../../api/sys/api_dept";

import axios from "axios";

export default {
  name: "orderview", // 跟进计划列表
  data() {
    return {
      orderId: undefined,
      orderInfo: {},
      //verifyPlanInfo: {},
      customerInfo: {},
      schemeInfo: {},
      reportInfo: {},
      detailList: [],
      deptInfo: {},
      workerInfo: {},

      selectedTab: "first",
      //trace tab
      traceList: [],
      //signal
      showRecordLoading: false,
      showCustomerInfoDialog: false,
      showTraceLoading: false,

      //init selectcustomerInfo edit
      selectCustomerInfo: {},

      showSchemeInfoDialog: false,
      showReportInfoDialog: false,

      showOrderDispatchDeptDialog: false,
      showOrderDispatchUserDialog: false,

      selectProviceList: [],
      selectCityList: [],
      selectAreaList: [],

      auth:{},

      //tree
      currentTreeNode: {},
      defaultProps: { children: "zones", label: "name", isLeaf: "leaf" },

      editSelectCustomerInfoRules: {
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            min: 2,
            max: 255,
            message: "长度在 2 到 255 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur",
          },
        ],
        province: [
          { required: true, message: "请输入省级地址", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        city: [
          { required: true, message: "请输入市级地址", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        area: [
          { required: true, message: "请输入县区地址", trigger: "blur" },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          {
            min: 2,
            max: 255,
            message: "长度在 2 到 255 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    //可以编辑客户快递信息
    computeOrderExpressEdit() {
      return OrderStatus.computeOrderExpressEdit(this.orderInfo?.status);
    },
    computeOrderDispatchDept() {
      return (
        this.orderInfo.status == 0 ||
        this.orderInfo.status == 1 ||
        this.orderInfo.status == 2 ||
        this.orderInfo.status == 3 ||
        this.orderInfo.status == 4
      );
    },
    computeOrderDispatchUser() {
      return (
        this.orderInfo.status == 0 ||
        this.orderInfo.status == 1 ||
        this.orderInfo.status == 2 ||
        this.orderInfo.status == 3 ||
        this.orderInfo.status == 4
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initPageData();
      this.auth=this.$getAuth()
    },
    initPageData() {
      this.showLoading = true;
      this.orderId = this.$route.query?.orderId;
      ORDER.get({ id: this.orderId }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.orderInfo = res?.data;
          this.verifyPlanInfo = res?.data?.verifyPlan;
          this.detailList = res?.data?.orderDetailList;
          this.customerInfo = res?.data?.customer;
          this.schemeInfo = res?.data?.scheme;
          this.reportInfo = res?.data?.report;
          this.deptInfo = res?.data?.toDept;
          this.workerInfo = res?.data?.toWorker;

          //init edit
          this.initSelectCustomerInfo();

          //init edit
          this.initSelectCustomerInfoAddr();
        } else {
          this.$message.error(res?.msg);
        }
      });
    },
    initSelectCustomerInfoAddr() {
      this.initProvinceList();
      this.initCityList();
      this.initAreaList();
    },

    initProvinceList() {
      AREA.listRoot().then((res) => {
        if (200 == res.code) {
          this.selectProvinceList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    initCityList() {
      if (this.selectCustomerInfo?.province) {
        let params = { parentName: this.selectCustomerInfo.province };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectCityList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    initAreaList() {
      if (this.selectCustomerInfo?.city) {
        let params = { parentName: this.selectCustomerInfo?.city };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectAreaList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    initSelectCustomerInfo() {
      this.selectCustomerInfo.contact = this.customerInfo?.contact;
      this.selectCustomerInfo.phone = this.customerInfo?.phone;
      this.selectCustomerInfo.province = this.customerInfo?.province;
      this.selectCustomerInfo.city = this.customerInfo?.city;
      this.selectCustomerInfo.area = this.customerInfo?.area;
      this.selectCustomerInfo.address = this.customerInfo?.address;
    },
    handler_tabclick(tab, event) {
      if (this.selectedTab === "second") {
        this.showRecordLoading = true;
        ORDERTRACE.list({
          pageNum: 1,
          pageSize: 100000,
          orderid: this.orderId,
        }).then((res) => {
          this.showRecordLoading = false;
          if (200 == res.code) {
            this.traceList = res?.pageData?.list;
          } else {
            this.$message.error(res?.msg);
          }
        });
      }
    },
    formatterProjectStatus(row) {
      let value = row?.status;
      return ProjectStatus.getLabelByValue(value);
    },
    //保存客户快递地址
    edit_save() {
      this.$refs["selectCustomerInfo"].validate((valid) => {
        if (valid) {
          let params = {};
          params.id = this.customerInfo.id;
          params.contact = this.selectCustomerInfo?.contact;
          params.phone = this.selectCustomerInfo?.phone;
          params.province = this.selectCustomerInfo?.province;
          params.city = this.selectCustomerInfo?.city;
          params.area = this.selectCustomerInfo?.area;
          params.address = this.selectCustomerInfo?.address;
          CUST.info(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.customerInfo.contact = this.selectCustomerInfo.contact;
                this.customerInfo.phone = this.selectCustomerInfo.phone;
                this.customerInfo.province = this.selectCustomerInfo.province;
                this.customerInfo.city = this.selectCustomerInfo.city;
                this.customerInfo.area = this.selectCustomerInfo.area;
                this.customerInfo.address = this.selectCustomerInfo.address;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        } else {
          return false;
        }
      });
    },
    handler_schemedownload() {
      try {
        SCHEME.download({ id: this.schemeInfo?.id })
          .then((msg) => {
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = window.URL.createObjectURL(new Blob([msg]));
            link.setAttribute("download", this.schemeInfo?.name + ".csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.$message.success("操作成功！");
          })
          .catch((error) => {
            console.error("promise catch err for download");
          });
      } catch (err) {
        console.warn("promise catch err");
      }
    },
    handler_schemereview() {
      this.showSchemeInfoDialog = true;
      let params = { id: this.schemeInfo?.id };

      SCHEME.review(params).then((res) => {
        if (200 == res?.code) {
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res?.msg);
        }
      });
    },

    handler_reportdownload() {
      try {
        REPORT.download({ id: this.reportInfo?.id })
          .then((msg) => {
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = window.URL.createObjectURL(new Blob([msg]));
            link.setAttribute("download", this.reportInfo?.name + ".csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.$message.success("操作成功！");
          })
          .catch((error) => {
            console.error("Promise catch err for download");
          });
      } catch (err) {
        console.warn("Promise catch err");
      }
    },

    handler_reportreview() {
      this.showReportInfoDialog = true;
      let params = { id: this.reportInfo?.id };
      REPORT.review(params).then((res) => {
        if (200 == res.code) {
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res?.msg);
        }
      });
    },

    handler_closeCustomerInfoDialog() {
      this.showCustomerInfoDialog = false;
    },
    handler_openCustomerInfoDialog() {
      this.showCustomerInfoDialog = true;
      this.initSelectCustomerInfo();
    },
    handler_changeProvince(value) {
      if (value) {
        this.selectCustomerInfo.province = value;
        this.selectCustomerInfo.city = undefined;
        this.selectCustomerInfo.area = undefined;
        this.selectCityList = [];
        this.selectAreaList = [];
        this.initCityList();
      }
    },
    handler_changeCity(value) {
      if (value) {
        this.selectCustomerInfo.city = value;
        this.selectCustomerInfo.area = undefined;
        this.selectAreaList = [];
        this.initAreaList();
      }
    },
    handler_ordercomplete() {
      this.$confirm("此操作将办结订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { orderId: this.orderInfo?.id };
          ORDER.complete(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.orderInfo.status = OrderStatus.COMPLETE;
              } else {
                this.$message.error(res?.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        })
        .catch((e) => {
          console.error("promise catch error");
        });
    },
    handler_orderstartverify() {
      this.$confirm("此操作将执行验证订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { orderId: this.orderInfo.id };
          ORDER.startVerify(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.orderInfo.status = OrderStatus.STARTVERIFY;
              } else {
                this.$message.error(res?.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        })
        .catch(() => {});
    },
    handler_orderstartcollect() {
      this.$confirm("此操作将执行数据采集, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { orderId: this.orderInfo.id };
          ORDER.startCollect(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.orderInfo.status = OrderStatus.STARTCOLLECT;
              } else {
                this.$message.error(res?.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        })
        .catch(() => {});
    },
    formatterOrderTraceType(row) {
      let val = row?.tracetype;
      return OrderTraceType.getLabelByValue(val);
    },
    handler_deletedetail(row) {
      this.$confirm("此操作将从订单里删除项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = { orderId: this.orderId, orderDetailId: row.id };
        ORDER.deleteDetailByOrderIdAndDetailId(params)
          .then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
              let index = this.detailList.findIndex(
                (detail) => detail.id === row.id
              );
              if (index > -1) {
                this.detailList.splice(index, 1);
              }
            } else {
              this.$message.error(res?.msg);
            }
          })
          .catch((err) => console.error("Promis error", err));
      });
    },
    handler_saveOrderDispatchDetp() {
      if (this.currentTreeNode == null) {
        this.$message.error("请选择部门！");
        return;
      }
      let node = this.currentTreeNode?.data;

      let params = { orderId: this.orderInfo.id, toDeptId: node?.id };
      ORDER.dispatchDept(params).then((res) => {
        if (200 == res.code) {
          this.$message.success("操作成功！");
          this.showOrderDispatchDeptDialog = false;
          this.deptInfo = res.data;
          this.orderInfo.did = this.deptInfo.id;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handler_saveOrderDispatchUser() {
      if (this.currentTreeNode == null) {
        this.$message.error("请选择负责人！");
        return;
      }
      let node = this.currentTreeNode.data;

      let params = { orderId: this.orderInfo.id, toUserId: node.id };
      ORDER.dispatchUser(params).then((res) => {
        if (200 == res.code) {
          this.$message.success("操作成功！");
          this.showOrderDispatchUserDialog = false;
          this.workerInfo = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async loadTreeNodeDept(node, resolve) {
      if (node.level === 0) {
        let params = { id: this.orderInfo?.did, isLoadUser: false };
        let data = await DEPT.getUserTree(params);
        return resolve(data);
      }
      if (node.data && (node.data.isSelect || node.data.leaf)) {
        return resolve([]);
      }
      DEPT.getUserTree({ id: node.data.id, isLoadUser: false }).then((data) => {
        return resolve(data);
      });
    },
    async loadTreeNodeUser(node, resolve) {
      if (node.level === 0) {
        let params = { id: this.orderInfo?.did, isLoadUser: true };
        let data = await DEPT.getUserTree(params);
        return resolve(data);
      }
      if (node.data && (node.data.isSelect || node.data.leaf)) {
        return resolve([]);
      }
      DEPT.getUserTree({ id: node.data.id, isLoadUser: false }).then((data) => {
        return resolve(data);
      });
    },
    handler_setCurrentTreeNode(data, node, e) {
      if (
        this.currentTreeNode &&
        this.currentTreeNode.data &&
        node?.data?.id == this.currentTreeNode?.data?.id
      ) {
        return;
      }

      this.currentTreeNode = node;
    },
  },
};
</script>


<style scoped>
.tabs-box {
  width: 1000px;
  margin: 20px auto;
}
.block-box {
  width: 100%;
  margin-bottom: 20px;
  float: left;
}
label.label {
  display: inline-block;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  color: #606266;
  width: 200px;
  margin-right: 15px;
}
.row span {
  font-size: 14px;
  color: #606266;
  margin-right: 15px;
}
.buttons {
  line-height: 50px;
  text-align: center;
}
.dialog-box {
  margin: 0 0 20px 20px;
}
.dispatch-box {
  margin-bottom: 10px;
}
.dispatch-box div {
  line-height: 50px;
}
</style>