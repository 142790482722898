/**
 * 项目状态
 * 0： 创建； 1： 待完善； 3： 待验证 ，',
 */

export default {
    NEW: 0,
    INCOMPLETE: 1,
    INVERIFY: 3,
    statuses: [{
        label: '创建',
        value: '0'
    }, {
        label: '待完善',
        value: '1'
    }, {
        label: '待验证',
        value: '3'
    }, ],
    getLabelByValue: (value) => {
        switch (value) {
            case 0:
                return "创建";
            case 1:
                return "待完善";
            case 3:
                return "待验证";
            default:
                return "未知";
        }
    },
};
