/**
 * 地址接口
 */

import * as API from '../../utils/request';

export default {

    /**
     * 省级根地址
     */
    listRoot: params => {
        return API.GET('area/listRoot', params)
    },
    /**
     * 市县区地址
     */

    listChildrenByParentName: params => {
        return API.GET('area/listChildrenByParentName', params)
    },
}