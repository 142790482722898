/**
 * 订单状态
 * 0： 创建； 1： 待处理； 2： 定稿, /**100 订单取消
 */
export default {
    NEW: 0,
    INPROGRESS: 1,
    COMPLETE: 2,
    CANCEL: 100,
    statuses: [{
        label: '创建',
        value: '0'
    }, {
        label: '开始验证',
        value: '1'
    }, {
        label: '结束验证',
        value: '2'
    }, {
        label: '开始采集',
        value: '3'
    }, {
        label: '结束采集',
        value: '4'
    }, {
        label: '办结',
        value: '90'
    }, {
        label: '取消',
        value: '100'
    }, ],
    getLabelByValue: (value) => {
        switch (value) {
            case 0:
                return "创建";
            case 1:
                return "开始验证";
            case 2:
                return "结束验证";
            case 3:
                return "开始采集";
            case 4:
                return "结束采集";
            case 5:
                return "办结订单";
            default:
                return "未知";
        }
    },
    computeOrderExpressEdit: value => {
        if (value === 0 || value === 1 || value === 2) {
            return true
        }
        return false;
    },



}