/**
 * Created by xiongxiaomeng.
 * 客户相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    add: params => {  return API.POST('customer/add', params)},
    update: params => {  return API.POST('customer/update', params)},
    list: params => {  return API.GET('customer/list', params)},
    get: params => {  return API.GET('customer/get', params)},
    delete: params => {  return API.DELETE('customer/delete', params)},
    listInFollowCustomer: params => {return API.GET('customer/listInFollowCustomer', params)},
}
